import React, {useContext} from 'react';
import {LanguageContext} from "../context/LanguageContext";
import PressureRecordsCard from "../components/PressureRecordsCard";

const headerText = {
    en: "Development of applications for Android smartphones",
    es: "Desarrollo de aplicaciones para teléfonos inteligentes Android"
}

const HomePage = () => {
    const {lang} = useContext(LanguageContext);
    return (
        <div>
            <h6 className="text-start ms-3">{(lang === "English") ? headerText.en : headerText.es}</h6>
            <div className="container mt-5 mb-5">
                <PressureRecordsCard />
            </div>
        </div>
    );
};

export default HomePage;
