import React, {useContext} from 'react';
import CardPage from '../components/CardPage';
import {LanguageContext} from "../context/LanguageContext";
import {Link} from "react-router-dom";

const PressureRecords = () => {
    const {lang} = useContext(LanguageContext);
    const {title, description, policy,linkText, note} = (lang === "English") ? pageText.en : pageText.es;
    return (
        <div>
            <h4 className="h4 text-center mt-2 mb-2">{title}</h4>
            <p className="text-start ps-3 pe-3">{description}</p>
            <Link to="/pressure_policy" className="link-info ps-3 pe-3 h6">{policy}</Link>
            <div className="container">
                <div className="">
                    {
                        (lang === "English") ?
                            cardTextEn.map(({title, image, features}) => (
                                <CardPage title={title} image={image} features={features} key={title}/>
                            )) :
                            cardTextEs.map(({title, image, features}) => (
                                <CardPage title={title} image={image} features={features} key={title}/>
                            ))
                    }
                </div>

            </div>
            <p className="text-end pe-4 small">{note}</p>
            <Link to="/" className="btn btn-outline-info ms-5 mt-3">{linkText}</Link>
        </div>
    );
};

export default PressureRecords;


const pageText = {
    en: {
        title: "Pressure records",
        description: "If you want to save your blood pressure records in an easy way where you can see graphs and lists of all your records ordered by month, try this application for smartphones; available only for android devices.",
        policy: "See privacy policy",
        linkText: "Back",
        note: "Note: This application is in the approval phase"
    },
    es: {
        title: "Pressure records",
        description: "Si quieres guardar tus registros de presión arterial de una manera fácil donde podrás ver gráficos y listados de todos tus registros ordenados por mes, prueba esta aplicación para smartphones; disponible solo para dispositivos Android",
        policy: "Ver política de privacidad",
        linkText: "Regresar",
        note: "Nota: Esta aplicación se encuentra en fase de aprobación"
    }
}

interface ICardText {
    title: string,
    image: string,
    features: string[],
}

const cardTextEn: ICardText[] = [
    {
        title: "Main view",
        image: "/images/heartbeatHome.png",
        features: [
            "See last inserted record.",
            "Shows the maximum pressure of the last seven days.",
            "Look the minimum pressure of the last seven days."
        ]
    },
    {
        title: "Add a new record",
        image: "/images/heartbeatNewRecord.png",
        features: [
            "Easily add your data.",
            "Select the date and time.",
            "Add a short note of up to 40 characters."
        ]
    },
    {
        title: "Records view",
        image: "/images/heartbeatRecordList.png",
        features: [
            "View records by month.",
            "Change the month easily.",
            "List sorted by day of the month.",
        ]
    },
    {
        title: "Graphics view",
        image: "/images/heartbeatGraph.png",
        features: [
            "Look at a graph of the month.",
            "Change the month easily.",
            "Shows the maximum and minimum records of the month."
        ]
    }

];

const cardTextEs: ICardText[] = [
    {
        title: "Vista principal",
        image: "/images/heartbeatHome.png",
        features: [
            "Ver último registro insertado.",
            "Muestra la presión máxima de los últimos siete días.",
            "Observe la presión mínima de los últimos siete días."
        ]
    },
    {
        title: "Agregar un nuevo registro",
        image: "/images/heartbeatNewRecord.png",
        features: [
            "Agregue fácilmente sus datos.",
            "Seleccione la fecha y la hora.",
            "Añadir una nota breve de hasta 40 caracteres."
        ]
    },
    {
        title: "Vista de registros",
        image: "/images/heartbeatRecordList.png",
        features: [
            "Ver registros por mes.",
            "Cambia el mes fácilmente.",
            "Lista ordenada por día del mes."
        ]
    },
    {
        title: "Vista de gráficos",
        image: "/images/heartbeatGraph.png",
        features: [
            "Mira un gráfico del mes.",
            "Cambie el mes fácilmente.",
            "Muestra los registros máximos y mínimos del mes."
        ]
    }

];
