import React, {useContext} from 'react';
import {LanguageContext} from "../context/LanguageContext";

const NavigationBar = () => {
    const {lang, langState} = useContext(LanguageContext);

    const handleLanguageChange = (event: any) => {
        event.preventDefault();
        langState(event.target.value);
    }

    return (
        <nav className="bg-secondary">
            <div className="nav d-flex justify-content-between ms-2 me-2">
                <h1>Freemodeapp</h1>
                <select id="language" className="btn btn-primary ps-1 pe-1 mt-1 h-25"
                        onChange={event => handleLanguageChange(event)} value={lang}>
                    <option className="ps-1 pe-1 mt-1 h-25" value="English">English</option>
                    <option className="ps-1 pe-1 mt-1 h-25" value="Español">Español</option>
                </select>
            </div>
        </nav>
    );
};

export default NavigationBar;
