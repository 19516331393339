import React, {useContext} from 'react';
import {LanguageContext} from "../context/LanguageContext";
import PressurePolicyEn from "../policy/PressurePolicyEn";
import PressurePolicyEs from "../policy/PressurePolicyEs";

const PressurePolicy = () => {
    const {lang} = useContext(LanguageContext);
    const imagePath =  '/images/contact.png';
    return (
        <div className="container mt-5 mb-2">
            {
                (lang === "English") ?
                    <PressurePolicyEn />
                    :
                    <PressurePolicyEs />
            }
            <img alt="Contact" src={imagePath} className="ms-2 mt-2"/>
        </div>
    );
};

export default PressurePolicy;

