import React from 'react';

const PressurePolicyEs = () => {
    return (
        <div>
            <h1>Política de privacidad</h1>
            <p>Última actualización: 08 de febrero de 2022</p>
            <p>Esta Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, el uso y
                la divulgación de Su información cuando utiliza el Servicio y le informa sobre Sus derechos de
                privacidad y cómo la ley lo protege.</p>
            <p>Usamos sus datos personales para proporcionar y mejorar el Servicio. Al usar el Servicio, acepta la
                recopilación y el uso de información de acuerdo con esta Política de privacidad.</p>
            <h1>Interpretación y Definiciones</h1>
            <h2>Interpretación</h2>
            <p>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes
                condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que
                aparezcan en singular o en plural</p>
            <h2>Definiciones</h2>
            <p>A los efectos de esta Política de Privacidad:</p>
            <ul>
                <li>
                    <p><strong>Cuenta</strong> significa una cuenta única creada para que Usted acceda a nuestro
                        Servicio o partes de nuestro Servicio.</p>
                </li>
                <li>
                    <p><strong>Afiliado</strong> significa una entidad que controla, es controlada o está bajo control
                        común con una parte, donde "control" significa la propiedad del 50% o más de las acciones,
                        participación accionaria u otros valores con derecho a voto para la elección de directores u
                        otra autoridad administrativa..</p>
                </li>
                <li>
                    <p><strong>Aplicación</strong> significa el programa de software proporcionado por la Compañía
                        descargado por Usted en cualquier dispositivo electrónico, denominado Pressure records</p>
                </li>
                <li>
                    <p><strong>La Compañía</strong> (referida como "la Compañía", "Nosotros", "Nos" o "Nuestro" en este
                        Acuerdo) se refiere a Pressure records.</p>
                </li>
                <li>
                    <p><strong>País</strong> se refiere a: México</p>
                </li>
                <li>
                    <p><strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio, como
                        una computadora, un teléfono celular o una tableta digital.</p>
                </li>
                <li>
                    <p><strong>Datos personales</strong> son cualquier información que se relaciona con un individuo
                        identificado o identificable.</p>
                </li>
                <li>
                    <p><strong>Servicio</strong> se refiere a la Aplicación.</p>
                </li>
                <li>
                    <p><strong>Proveedor de servicios</strong> significa cualquier persona física o jurídica que procesa
                        los datos en nombre de la Compañía. Se refiere a empresas de terceros o personas empleadas por
                        la Empresa para facilitar el Servicio, proporcionar el Servicio en nombre de la Empresa,
                        realizar servicios relacionados con el Servicio o ayudar a la Empresa a analizar cómo se utiliza
                        el Servicio.</p>
                </li>
                <li>
                    <p><strong>Datos de uso</strong> se refieren a los datos recopilados automáticamente, ya sea
                        generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la
                        duración de una visita a la página).</p>
                </li>
                <li>
                    <p><strong>Usted</strong> se refiere a la persona que accede o utiliza el Servicio, o la empresa u
                        otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según
                        corresponda.</p>
                </li>
            </ul>
            <h1>Recopilación y uso de sus datos personales</h1>
            <h2>Tipos de datos recopilados</h2>
            <h3>Información personal</h3>
            <p>Mientras usa Nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación
                personal que se puede usar para contactarlo o identificarlo. La información de identificación personal
                puede incluir, entre otros:</p>
            <ul>
                <li>Datos de uso</li>
            </ul>
            <h3>Datos de uso</h3>
            <p>Los Datos de uso se recopilan automáticamente cuando se utiliza el Servicio.</p>
            <p>Los datos de uso pueden incluir información como la dirección del protocolo de Internet de su dispositivo
                (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro
                Servicio que visita, la hora y la fecha de su visita, el tiempo que pasa en esas páginas, dispositivo
                único identificadores y otros datos de diagnóstico.</p>
            <p>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información
                automáticamente, que incluye, entre otros, el tipo de dispositivo móvil que utiliza, la identificación
                única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo, el tipo de
                navegador de Internet móvil que utiliza, identificadores únicos de dispositivos y otros datos de
                diagnóstico.</p>
            <p>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o
                cuando accede al Servicio a través de un dispositivo móvil.</p>
            <h2>Uso de sus datos personales</h2>
            <p>La Compañía puede utilizar los Datos Personales para los siguientes propósitos:</p>
            <ul>
                <li>
                    <p><strong>Para proporcionar y mantener nuestro Servicio</strong>, incluso para monitorear el uso de
                        nuestro Servicio.</p>
                </li>
                <li>
                    <p><strong>Para gestionar Su Cuenta:</strong> para gestionar Su registro como usuario del Servicio.
                        Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del
                        Servicio que están disponibles para Usted como usuario registrado.</p>
                </li>
                <li>
                    <p><strong>Para la ejecución de un contrato:</strong> el desarrollo, cumplimiento y realización del
                        contrato de compra de los productos, artículos o servicios que haya adquirido o de cualquier
                        otro contrato con Nosotros a través del Servicio.</p>
                </li>
                <li>
                    <p><strong>Para contactarlo:</strong> para contactarlo por correo electrónico, llamadas telefónicas,
                        SMS u otras formas equivalentes de comunicación electrónica, como notificaciones push de una
                        aplicación móvil sobre actualizaciones o comunicaciones informativas relacionadas con las
                        funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad,
                        cuando sea necesario o razonable para su implementación.</p>
                </li>
                <li>
                    <p><strong>Para brindarle</strong> noticias, ofertas especiales e información general sobre otros
                        bienes, servicios y eventos que ofrecemos que son similares a los que ya compró o preguntó, a
                        menos que haya optado por no recibir dicha información.</p>
                </li>
                <li>
                    <p><strong>Para gestionar Sus solicitudes:</strong> Para atender y gestionar Sus solicitudes hacia
                        Nosotros.</p>
                </li>
                <li>
                    <p><strong>Para transferencias comerciales:</strong> podemos usar su información para evaluar o
                        realizar una fusión, venta, reestructuración, reorganización, disolución u otra venta o
                        transferencia de algunos o todos nuestros activos, ya sea como una empresa en marcha o como
                        parte de una quiebra, liquidación, o procedimiento similar, en el que los Datos personales que
                        tenemos sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos.</p>
                </li>
                <li>
                    <p><strong>Para otros fines:</strong> podemos utilizar su información para otros fines, como el
                        análisis de datos, la identificación de tendencias de uso, la determinación de la eficacia de
                        nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios,
                        marketing y su experiencia.</p>
                </li>
            </ul>
            <p>Podemos compartir su información personal en las siguientes situaciones:</p>
            <ul>
                <li><strong>Con proveedores de servicios:</strong> podemos compartir su información personal con
                    proveedores de servicios para monitorear y analizar el uso de nuestro servicio, para mostrarle
                    anuncios para ayudar a respaldar y mantener nuestro servicio, para contactarlo.
                </li>
                <li><strong>Para transferencias comerciales:</strong> Podemos compartir o transferir Su información
                    personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la
                    Compañía, financiamiento o adquisición de todo o una parte de Nuestro negocio a otra compañía.
                </li>
                <li><strong>Con afiliados:</strong> podemos compartir su información con nuestros afiliados, en cuyo
                    caso exigiremos a esos afiliados que respeten esta Política de privacidad. Los afiliados incluyen
                    nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas
                    que controlamos o que están bajo control común con nosotros.
                </li>
                <li><strong>Con socios comerciales:</strong> Podemos compartir Su información con Nuestros socios
                    comerciales para ofrecerle ciertos productos, servicios o promociones.
                </li>
                <li><strong>Con otros usuarios:</strong> cuando comparte información personal o interactúa en las áreas
                    públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede
                    distribuirse públicamente al exterior.
                </li>
                <li><strong>Con su consentimiento:</strong> podemos divulgar su información personal para cualquier otro
                    propósito con su consentimiento.
                </li>
            </ul>
            <h2>Retención de sus datos personales</h2>
            <p>La Compañía conservará sus Datos personales solo durante el tiempo que sea necesario para los fines
                establecidos en esta Política de privacidad. Conservaremos y utilizaremos sus datos personales en la
                medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a
                conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestras
                políticas y acuerdos legales.</p>
            <p>La Compañía también conservará los Datos de uso para fines de análisis interno. Los datos de uso
                generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se usan para
                fortalecer la seguridad o mejorar la funcionalidad de nuestro servicio, o cuando estamos legalmente
                obligados a retener estos datos por períodos de tiempo más largos.</p>
            <h2>Transferencia de sus datos personales</h2>
            <p>Su información, incluidos los Datos personales, se procesa en las oficinas operativas de la Compañía y en
                cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Significa que esta
                información puede transferirse y mantenerse en computadoras ubicadas fuera de Su estado, provincia, país
                u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de Su
                jurisdicción.</p>
            <p>Su consentimiento a esta Política de privacidad seguido de Su envío de dicha información representa Su
                acuerdo con esa transferencia.</p>
            <p>La Compañía tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de
                forma segura y de acuerdo con esta Política de privacidad y no se realizará ninguna transferencia de sus
                datos personales a una organización o país a menos que existan controles adecuados establecidos,
                incluida la seguridad de Sus datos y otra información personal.</p>
            <h2>Divulgación de sus datos personales</h2>
            <h3>Transacciones de negocios</h3>
            <p>Si la Compañía está involucrada en una fusión, adquisición o venta de activos, Sus Datos personales
                pueden transferirse. Le enviaremos un aviso antes de que sus Datos personales se transfieran y queden
                sujetos a una Política de privacidad diferente.</p>
            <h3>Cumplimiento de la ley</h3>
            <p>En determinadas circunstancias, es posible que se le solicite a la Compañía que divulgue sus Datos
                personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (por
                ejemplo, un tribunal o una agencia gubernamental)..</p>
            <h3>Otros requisitos legales</h3>
            <p>La Compañía puede divulgar sus datos personales de buena fe cuando considere que esta acción es necesaria
                para lo siguiente:</p>
            <ul>
                <li>Cumplir con una obligación legal</li>
                <li>Proteger y defender los derechos o propiedad de la Compañía</li>
                <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
                <li>Proteger la seguridad personal de los Usuarios del Servicio o del público</li>
                <li>Protéjase contra la responsabilidad legal</li>
            </ul>
            <h2>Seguridad de sus datos personales</h2>
            <p>La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de
                transmisión por Internet o método de almacenamiento electrónico es 100 % seguro. Si bien nos esforzamos
                por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar
                su seguridad absoluta.</p>
            <h1>Información detallada sobre el procesamiento de sus datos personales</h1>
            <p>Los proveedores de servicios que utilizamos pueden tener acceso a sus datos personales. Estos proveedores
                externos recopilan, almacenan, usan, procesan y transfieren información sobre Su actividad en Nuestro
                Servicio de acuerdo con sus Políticas de privacidad..</p>
            <h2>Publicidad</h2>
            <p>Podemos utilizar proveedores de servicios para mostrarle anuncios para ayudar a respaldar y mantener
                nuestro servicio.</p>
            <ul>
                <li>
                    <p><strong>AdMob de Google</strong></p>
                    <p>AdMob de Google es proporcionado por Google Inc.</p>
                    <p>Puede darse de baja del servicio AdMob de Google siguiendo las instrucciones descritas por
                        Google: <a href="https://support.google.com/ads/answer/2662922?hl=en"
                                   rel="external nofollow noopener"
                                   target="_blank">https://support.google.com/ads/answer/2662922?hl=en</a></p>
                    <p>Para obtener más información sobre cómo usa Google la información recopilada, visite la página
                        "Cómo usa Google los datos cuando usa los sitios o las aplicaciones de nuestros socios": <a
                            href="https://policies.google.com/technologies/partner-sites"
                            rel="external nofollow noopener"
                            target="_blank">https://policies.google.com/technologies/partner-sites</a> o visite la
                        página Política de Privacidad de Google: <a href="https://policies.google.com/privacy"
                                                                    rel="external nofollow noopener"
                                                                    target="_blank">https://policies.google.com/privacy</a>
                    </p>
                </li>
            </ul>
            <h1>Privacidad de los niños</h1>
            <p>Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No recopilamos a sabiendas información
                de identificación personal de ninguna persona menor de 13 años. Si usted es padre o tutor y sabe que su
                hijo nos ha proporcionado Datos personales, por favor Contáctenos. Si nos damos cuenta de que hemos
                recopilado Datos personales de cualquier persona menor de 13 años sin verificación del consentimiento de
                los padres, tomamos medidas para eliminar esa información de Nuestros servidores.</p>
            <p>Si necesitamos basarnos en el consentimiento como base legal para procesar su información y su país
                requiere el consentimiento de uno de sus padres, podemos solicitar el consentimiento de su padre antes
                de recopilar y usar esa información.</p>
            <h1>Enlaces a otros sitios web</h1>
            <p>Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por Nosotros. Si hace clic
                en el enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que
                revise la Política de privacidad de cada sitio que visite.</p>
            <p>No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o
                las prácticas de los sitios o servicios de terceros.</p>
            <h1>Cambios a esta Política de Privacidad</h1>
            <p>Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier
                cambio publicando la nueva Política de privacidad en esta página.</p>
            <p>Le informaremos por correo electrónico y/o un aviso destacado en Nuestro Servicio, antes de que el cambio
                entre en vigencia y actualizaremos la fecha de "Última actualización" en la parte superior de esta
                Política de privacidad.</p>
            <p>Se le recomienda revisar esta Política de Privacidad periódicamente para cualquier cambio. Los cambios a
                esta Política de privacidad son efectivos cuando se publican en esta página.</p>
            <h1>Contáctenos</h1>
            <p>Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:</p>
        </div>
    );
};

export default PressurePolicyEs;
