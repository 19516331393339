import React, {useContext} from 'react';
import {LanguageContext} from "../context/LanguageContext";

const Footer = () => {
    const {lang} = useContext(LanguageContext);
    return (
        <footer className="bg-secondary mt-5 w-100">
            <div className="d-flex justify-content-center">
            &copy; Freemodeapp 2022 -----  {lang}
            </div>
        </footer>
    );
};

export default Footer;
