import React, {useState} from 'react';
import {LanguageContext} from "./context/LanguageContext";
import Navigation from "./routes/Navigation";

function App() {
    const [lang, langState] = useState<any>("English");

    return (
        <LanguageContext.Provider value={{
            lang,
            langState,
        }}>
            <>
            <Navigation />
            </>
        </LanguageContext.Provider>
    );
}

export default App;
