import React, {Suspense} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {routes} from "./routes";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import HomePage from "../pages/HomePage";
import PressureRecords from "../pages/PressureRecords";
import PressurePolicy from "../pages/PressurePolicy";


const Navigation = () => {
    return (
        <Suspense fallback={<span>Loading...</span>}>
            <BrowserRouter>
                <NavigationBar />
                <div>
                    <Routes>
                        <Route element={<HomePage />} path="/" key={"home"} />
                        <Route element={<PressureRecords />} path="/pressure_records" key={"pressure_records"} />
                        <Route element={<PressurePolicy />} path="/pressure_policy" key={"pressure_policy"} />
                        <Route element={<Navigate to="/"/>} path="*"/>
                    </Routes>
                </div>
                <Footer />
            </BrowserRouter>
        </Suspense>
    );
};

export default Navigation;
