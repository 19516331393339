import React from 'react';
import "./card_page.css"
import PropTypes from 'prop-types';

const CardPage = (props: ICardText) => {
    const {title, image, features} = props;
    return (
        <div className="card bg-light shadow p-2 border-2 card__decoration">
            <h5 className="card-title mt-2 mb-2">{title}</h5>
            <img src={image} alt={image} className="image__screen mx-auto" />
            <ul className="mt-2 mx-auto list-group list-group-flush">
                {
                    features.map(value => (
                        <li className="list-group-item" key={value}>{value}</li>
                    ))
                }
            </ul>
        </div>
    );
};

CardPage.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    features: PropTypes.array.isRequired,

};

export default CardPage;

interface ICardText {
    title: string,
    image: string,
    features: string[]
}
