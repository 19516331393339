import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import "./home_card.css"
import {LanguageContext} from "../context/LanguageContext";

const PressureRecordsCard = () => {
    const {lang} = useContext(LanguageContext);
    const {title, icon, description, linkText, status} = (lang ==="English") ? cardText.en : cardText.es;
    const imagePath =  '/images/heartbeatIcon.png';

    return (
        <article className="card card__decoration bg-light shadow p-2">
            <div className="card-body">
                <h4 className="h4">{title}</h4>
                <img className="card__image mb-3 mt-3" src={imagePath} alt={icon}/>
                <p className="text-start">{description}</p>
                <Link to="/pressure_records" className="btn btn-outline-info float-start mt-5">{linkText}</Link>
                <small className="text-end text-sm-end float-end mt-5">{status}</small>
            </div>
        </article>
    );
};

export default PressureRecordsCard;


const cardText = {
    en: {
        title: "Pressure records",
        icon: "Icon image",
        description: "Pressure records is an application for mobile devices to record blood pressure measurements",
        linkText: "See details",
        status: "Coming soon"
    },
    es: {
        title: "Pressure records",
        icon: "Icono imagen",
        description: "Pressure records es una aplicación para dispositivos móviles para registrar las mediciones de la presión arterial",
        linkText: "Ver detalles",
        status: "Próximamente"
    }
}
